import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { Component } from 'vue-property-decorator'

@Component({
  name: 'GtrRegistrationModuleNewPricingTierView'
})
export default class GtrRegistrationModuleNewPricingTierView extends GtrSuper {
  data () {
    return {
      submitting: false,
      pricingTier: {
        name: null,
        datetime_start: null,
        datetime_end: null
      }
    }
  }

  async submit () {
    const form = (this.$refs.newPricingTierForm as HTMLFormElement)
    if (form.validate()) {
      try {
        this.$data.submitting = true
        const payload: any = {
          event_uuid: this.$route.params.event_uuid,
          data: {
            name: this.$data.pricingTier.name,
            date_start: this.convertDatetimeToUTC(this.$data.pricingTier.datetime_start),
            date_end: this.convertDatetimeToUTC(this.$data.pricingTier.datetime_end)
          }
        }
        await this.$store.dispatch('pricing/createPricingTier', payload)
        Container.get(Notification).success('Pricing tier successfully created.')
        this.$router.push({
          name: 'level-two.modules.registration.pricing-tiers'
        })
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.submitting = false
      }
    }
  }
}
